export const AMPLITUDE_EVENT_NAMES = {
    BMP_TAG_CLICKED: "bmp tag clicked"
};

export const GA_EVENTS = {
    BMP_TAG_CLICKED: {
        eventAction: "bmp_tag_clicked",
        eventLabel: "clp",
        eventCategory: "Cars24_listing_page"
    }
};
