import React, {useCallback} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withAuth from "../../shared/with-auth";
import { showWishlistPopup, fetchWishlistedCarsList, addOrRemoveFromWishlist } from "../my-wishlist/actions";
import { showToast } from "../../shared/toast-message/actions";
import { desktopCleverTapEvent, trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import debounce from "../../../utils/helpers/debounce";
import { NUMBER } from "../../../constants/app-constants";
import clevertapDetails from "../../../tracking/parser/clevertap-details";
import { CLEVERTAP_EVENT_MAP } from "../../../tracking/au.desktop-clevertap";

export default function withWishlist(Component) {
    const WithWishlist = (props) => {
        const {
            wishlistedCarsList,
            addOrRemoveFromWishlistConnect,
            secureToken,
            showToastConnect,
            detailsConfig,
            detailsContent
        } = props;

        const isWishlistedCar = (carId) => {
            return (wishlistedCarsList || []).indexOf(carId) !== -1;
        };

        // eslint-disable-next-line max-params
        const onClickAddToWishList = (vehicleId, token, gtmFrom, {
            comingSoon,
            make,
            model,
            shortlist,
            callback = () => {},
            errorCallback = () => {},
            toastStyle,
            carStatus = {},
            showNotifyPopup
        } = {}) => {
            const shortListed = shortlist ? shortlist : !isWishlistedCar(vehicleId);
            let params = {shortListed, vehicleId, comingSoon, make, model};
            params = carStatus.comingSoon ? {...params, ...carStatus} : params;
            addOrRemoveFromWishlistConnect(token ? token : secureToken, params).then(() => {
                if (shortListed) {
                    showToastConnect({
                        variant: "shadowGreen",
                        text: "Added to Wishlist",
                        ...(toastStyle && { toastStyle })
                    });
                    trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.WISHLIST_CTA_SELECTED, {eventLabel: `options ${gtmFrom}`});
                } else {
                    showToastConnect({
                        variant: "shadowRed",
                        text: "Removed from Wishlist",
                        ...(toastStyle && { toastStyle })
                    });
                    trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.WISHLIST_CTA_DESELECTED, {eventLabel: `options ${gtmFrom}`});
                }
                const data = clevertapDetails(detailsContent, {prefix: "wishlist_", config: detailsConfig, shortListed});
                desktopCleverTapEvent(CLEVERTAP_EVENT_MAP.WISHLIST_DONE, data);
                callback(shortListed, showNotifyPopup);
            }).catch(errorCallback);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debounceWishlist = useCallback(debounce(onClickAddToWishList, NUMBER.THREE_HUNDRED), [isWishlistedCar]);

        return (
            <Component
                {...props}
                isWishlistedCar={isWishlistedCar}
                onClickAddToWishList={debounceWishlist}
            />
        );
    };

    WithWishlist.propTypes = {
        showWishlistPopupConnect: PropTypes.func,
        fetchWishlistedCarsListConnect: PropTypes.func,
        wishlistedCarsList: PropTypes.array,
        addOrRemoveFromWishlistConnect: PropTypes.func,
        secureToken: PropTypes.string,
        showToastConnect: PropTypes.func,
        detailsConfig: PropTypes.object,
        detailsContent: PropTypes.object
    };

    const mapStateToProps = ({
        myWishlist: { wishlistedCarsList },
        carDetails: {config: detailsConfig, content: detailsContent} }) => {
        return { wishlistedCarsList, detailsConfig, detailsContent};
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        showWishlistPopupConnect: showWishlistPopup,
        fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
        addOrRemoveFromWishlistConnect: addOrRemoveFromWishlist,
        showToastConnect: showToast
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        withAuth(WithWishlist)
    );
}
