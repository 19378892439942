import { b2cBffListingAU, ListingServiceAU as ListingService, ListingServiceAUV2} from "../../../service";
import Types from "./types";
import { updateAppliedFilters} from "../filters/actions";
import { resetCityLocation } from "../location-picker-popup/actions";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import parseCookie from "../../../utils/helpers/parse-cookie";
import Filters from "../../../utils/filters-v2";
import { AU_RECENTLY_VIEWD_KEY, COOKIE_SEARCH_KEYWORDS, RECENTLY_APPLIED_FILTERS } from "../../../constants/app-constants";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchSimilarCarSuccess = (data, params) => ({
    type: Types.FETCH_SIMILAR_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    let cityObj = null;
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchCarListSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    let cityObj = null;
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchCarListSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarListInit = () => ({
    type: Types.FETCH_SIMILAR_CAR_LIST
});
const fetchSimilarCarListFailure = (error) => ({ type: Types.FETCH_SIMILAR_CAR_LIST_FAILURE, error });

const fetchSimilarCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchSimilarCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    let cityObj = null;
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                dispatch(fetchSimilarCarSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchSimilarCarListFailure({error}));
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const resetCarList = ()  => ({
    type: Types.RESET_CAR_LIST
});

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

const fetchSearchPageInit = () => ({
    type: Types.FETCH_SEARCH_PAGE_INIT,
    loadingSearch: true
});

const fetchSearchPagesSuccess = (data) => ({
    type: Types.FETCH_SEARCH_PAGE_SUCCESS,
    data
});

const fetchSearchPagesFailure = () => ({
    type: Types.FETCH_SEARCH_PAGE_FAILURE

});

// eslint-disable-next-line max-statements
const fetchSearchResult = (payload = {}, params) => async (dispatch, getState) => {
    const {
        filters: {
            allFilters
        },
        cities: {
            selectedCity: {
                code: selectedCityCode
            } = {}
        } } = getState();

    dispatch(fetchSearchPageInit());
    const searchedKeyword = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
    const selectedFilters = JSON.parse(parseCookie(RECENTLY_APPLIED_FILTERS)) || [];
    const currentFilters = new Filters({ cityCode: selectedCityCode });
    const { payload: topFilterSearchUrl } = currentFilters.getListingPayload({
        selectedFilters,
        filters: allFilters
    });

    const {user: { secureToken }} = getState();
    try {
        const requestBody = {
            ...payload,
            recurringUser: !!secureToken,
            appointmentIdList: JSON.parse(parseCookie(AU_RECENTLY_VIEWD_KEY)),
            recentlySearched: searchedKeyword ? searchedKeyword.map(item => item.value) : [],
            ...topFilterSearchUrl && {topFilterSearchUrl}
        };
        const response = await b2cBffListingAU.fetchSearchPage(requestBody, secureToken, params);
        const {result = [], config} = response.data || {};
        let obj = {};
        result.forEach(item => (obj[item.type] = Array.isArray(item.templates) ? item.templates[0] : item.templates));
        /**
         * UNCOMMENT THE BELOW CODE TO GET RECENTLY APPLIED FILTER FROM API INSTEAD OF COOKIE
         * Change if (selectedFilters.length > 0) to if (personalizedFilters)
         */
        //const { personalizedFilters = {} } = obj;
        //if (personalizedFilters) {
        if (selectedFilters.length > 0) {
            //const { result: personalizedFiltersResult = {} } = personalizedFilters;
            //const parsedFilters = getFiltersData(personalizedFiltersResult, true);
            //const { appliedFilters = [] } = initAppliedFilters(parsedFilters.allFilters) || {};
            obj = {
                ...obj,
                personalizedFilters: selectedFilters
            };
        }

        dispatch(fetchSearchPagesSuccess({config, ...obj }));
    } catch (error) {
        dispatch(fetchSearchPagesFailure(error));
    }
};

// Fetch FAQ Data
const fetchClpDataInit = () => ({
    type: Types.FETCH_FAQ_DATA
});

const fetchClpDataSuccess = (data, params) => ({
    type: Types.FETCH_FAQ_DATA_SUCCESS,
    data,
    params
});

const fetchClpDataFailure = (error) => ({ type: Types.FETCH_FAQ_DATA_FAILURE, error });

const fetchClpData = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchClpDataInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };

    return new Promise((resolve, reject) => {
        ListingService.fetchClpData(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchClpDataSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchClpDataFailure(error));
                reject(error);
            });
    });
};
export {
    fetchCarList,
    fetchCarListV2,
    fetchSimilarCarListV2,
    updateSSRStatus,
    resetCarList,
    reloadCarList,
    fetchSearchResult,
    fetchClpData
};
