import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_DETAILS
    FETCH_CAR_DETAILS_SUCCESS
    FETCH_CAR_DETAILS_FAILURE

    UPDATE_SSR_STATUS

    FETCH_SIMILAR_CARS
    FETCH_SIMILAR_CARS_SUCCESS
    FETCH_SIMILAR_CARS_FAILURE

    SET_GS_GALLERY_DATA

    GET_DELIVERY_INFO_REQUEST
    GET_DELIVERY_INFO_SUCCESS
    GET_DELIVERY_INFO_FAILURE

    GET_DRIVA_ROI_REQUEST
    GET_DRIVA_ROI_SUCCESS
    GET_DRIVA_ROI_FAILURE

    GET_DELIVERY_TIMELINE_REQUEST
    GET_DELIVERY_TIMELINE_SUCCESS
    GET_DELIVERY_TIMELINE_FAILURE

    UPDATE_LOGIN_CAR_DETAIL_FETCH_COMPLETED

    RESET_CAR_DETAILS

    FETCH_COMPARISION_DETAILS_SUCCESS
    FETCH_COMPARISION_DETAILS_FAILURE

    FETCH_INSPECTION_REPORT_DATA_SUCCESS
    FETCH_INSPECTION_REPORT_DATA_FAILURE
    SET_CURRENT_DELIVERY_TIMELINE

    SET_DRIVE_AWAY_PRICE_MODAL
    SET_REDIRECT_ROUTE
    
    FETCH_TOTAL_CAR_SUCCESS
    FETCH_TOTAL_CAR_FAILURE

    CLEAR_SIMILAR_CAR_LIST
    SET_CAR_CARD_LIST_INDEX

    PRE_POPULATE_DETAILS
`,
    {
        prefix: "cardetails/"
    }
);
