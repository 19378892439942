import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    FETCH_SIMILAR_CAR_LIST
    FETCH_SIMILAR_CAR_LIST_SUCCESS
    FETCH_SIMILAR_CAR_LIST_FAILURE

    UPDATE_SSR_STATUS
    RELOAD_CAR_LIST
    RESET_CAR_LIST

    HANDLE_CAR_COMPARE_POPUP
    ADD_CAR_TO_COMPARE
    REMOVE_CAR_FROM_COMPARE
    FETCH_CAR_COMPARE_SUCCESS

    FETCH_FAQ_DATA
    FETCH_FAQ_DATA_SUCCESS
    FETCH_FAQ_DATA_FAILURE

    UPDATE_FINANCE_CENTRIC_LISTING

    SET_CAR_LOAN_CALCULATOR_META_DATA
    SET_CAR_LOAN_CALCULATOR_LOADING
    SET_CAR_LOAN_CALCULATOR_ERROR
    SET_CAR_LOAN_INTEREST_RATES
    `,
    {
        prefix: "carlisting/"
    }
);
