export const getAppliedFilterValues = (selectedFilters = []) => {
    const filtersObject = {};

    selectedFilters.forEach(filter => {
        const { filterKey = "", optionKey = "", isRangeFilter = false, max = 0 } = filter;
        if (optionKey) {
            filtersObject[filterKey] = optionKey;
        } else if (isRangeFilter) {
            filtersObject[filterKey] = max;
        } else {
            filtersObject[filterKey] = "";
        }
    });
    return filtersObject;
};

export const parsedAppliedFilters = (selectedFilters = []) => {
    const appliedFilters = {};

    selectedFilters.forEach(filter => {
        const { filterKey = "", optionKey = "" } = filter;
        if (appliedFilters[filterKey]) {
            appliedFilters[filterKey] += `, ${optionKey}`;
        } else {
            appliedFilters[filterKey] = optionKey;
        }
    });
    return appliedFilters;
};
