const { NUMBER } = require("../../constants/app-constants");
const { default: percentCalculator } = require("./percent-calculator");

const sliderPositionCalculator = ({kbbSliderMin, kbbSliderMax, egc}) => {
    const absolutePosition = ((egc - kbbSliderMin) / (kbbSliderMax - kbbSliderMin)) * NUMBER.HUNDRED;
    const adjustedPosition = percentCalculator(absolutePosition, NUMBER.FOURTY);
    if (adjustedPosition < 0) {
        return 0;
    } else if (adjustedPosition > NUMBER.FOURTY) {
        return NUMBER.FOURTY;
    } else {
        return adjustedPosition;
    }

};

export const getKbbSliderData = (carData) => {
    const {egc, kbbAskingPrice} = carData || {};
    const kbbDifference = kbbAskingPrice - egc;
    const kbbSliderMin = percentCalculator(kbbAskingPrice, NUMBER.EIGHTY);
    const kbbSliderMax = NUMBER.DECIMAL_ONE_TWO * kbbAskingPrice;
    const sliderposition = sliderPositionCalculator({kbbDifference, kbbSliderMax, kbbSliderMin, egc});
    const kbbSliderData = {kbbDifference, kbbSliderMax, kbbSliderMin, egc, sliderposition, kbbAskingPrice};
    return kbbSliderData;
};
