import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    FETCH_SIMILAR_CAR_LIST
    FETCH_SIMILAR_CAR_LIST_SUCCESS
    FETCH_SIMILAR_CAR_LIST_FAILURE

    UPDATE_SSR_STATUS
    RESET_CAR_LIST

    RELOAD_CAR_LIST
    
    FETCH_SEARCH_PAGE_INIT
    FETCH_SEARCH_PAGE_SUCCESS
    FETCH_SEARCH_PAGE_FAILURE

    FETCH_FAQ_DATA
    FETCH_FAQ_DATA_SUCCESS
    FETCH_FAQ_DATA_FAILURE
`,
    {
        prefix: "carlisting/"
    }
);
