import { createTypes } from "reduxsauce";

export default createTypes(
    `
        TOGGLE_LOCATION_PICKER
        SET_LOCATION_CITY
    `,
    {
        prefix: "locationpickerpopup/"
    }
);
