import { createTypes } from "reduxsauce";

export default createTypes(
    `
        UPDATE_SELECTED_CAR

        SHOW_WISHLIST_POPUP

        FETCH_WISHLISTED_CARS_LIST_INIT
        FETCH_WISHLISTED_CARS_LIST_SUCCESS
        FETCH_WISHLISTED_CARS_LIST_FAILURE

        FETCH_WISHLISTED_CARS_INIT
        FETCH_WISHLISTED_CARS_SUCCESS
        FETCH_WISHLISTED_CARS_FAILURE
        
        ADD_OR_REMOVE_FROM_WISHLIST_INIT
        ADD_OR_REMOVE_FROM_WISHLIST_SUCCESS
        ADD_OR_REMOVE_FROM_WISHLIST_FAILURE

        RESET_WISHLIST
`,
    {
        prefix: "mywishlist/"
    }
);
