import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <div>

            <a styleName={"styles.wrapper"}>

                <div styleName={"styles.mediaWrapper"} className="media">
                    <div className="shimmer" styleName={"styles.imageWrapper"} />
                    <div className="media-body">
                        <p className="shimmer" styleName={"styles.carName"} />
                        <p className="shimmer" styleName={"styles.carVariant"} />
                        <p className="shimmer" styleName={"styles.perWeek"} />
                        <p className="shimmer" styleName={"styles.carPrice"} />
                        <p className="shimmer" styleName={"styles.Egc"} />
                    </div>
                </div>
                <div styleName={"styles.tabWrapper"}>
                    <div className="shimmer" styleName={"styles.tab"} />
                    <div className="shimmer" styleName={"styles.tab"} />
                    <div className="shimmer" styleName={"styles.tab"} />
                </div>
            </a>

        </div>
    );
};

export default Skeleton;
