import Types from "./types";

const toggleLocationPicker = (status) => ({
    type: Types.TOGGLE_LOCATION_PICKER,
    status
});

const resetCityLocation = (cityObj) => ({
    type: Types.SET_LOCATION_CITY,
    cityObj
});

export {
    toggleLocationPicker,
    resetCityLocation
};
