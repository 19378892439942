import React from "react";
import Modal from "../../shared/modal";
import PropTypes from "prop-types";
import styles from "./styles.css";
import closeImage from "./images/close.svg";
import HowWeDetermineMarketPriceComponent from "../how-we-determine-market-price-component/component";

const HowWeDetermineMarketPriceModal = ({show, onClose, kbbSliderData}) => {
    return (
        <Modal isOpen={show}>
            <div styleName={"styles.modalOuter"}>
                <div styleName={"styles.closeWrapper"}>
                    <img src={closeImage} alt="Close modal icon" onClick={onClose} />
                </div>
                <HowWeDetermineMarketPriceComponent kbbSliderData={kbbSliderData} isModal={true} />
                <div styleName={"styles.contentWrap"}>
                    <p styleName={"styles.question"}>How do we determine market price?</p>
                    <p styleName={"styles.answer"}>CARS24 partners with Kelley Blue Book to ascertain a fair price for a used car in Australia.</p>
                    <p styleName={"styles.question"}>What is the Kelley Blue Book?</p>
                    <p styleName={"styles.answer"}>The Kelley Blue Book is a reliable go-to for car valuations. KBB offers comparative guides and fair market price estimations for prospective car buyers.</p>
                </div>
            </div>
        </Modal>
    );
};

HowWeDetermineMarketPriceModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    kbbSliderData: PropTypes.func
};

export default HowWeDetermineMarketPriceModal;
