import React, {useCallback} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withAuth from "../../shared/with-auth";
import { showWishlistPopup, fetchWishlistedCarsList, addOrRemoveFromWishlist } from "../my-wishlist/actions";
import { showToast } from "../../shared/toast-message/actions";
import { mobileCleverTapEvent, trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { NUMBER } from "../../../constants/app-constants";
import debounce from "../../../utils/helpers/debounce";
import { CLEVERTAP_EVENT_MAP } from "../../../tracking/au.mobile-clevertap";
import clevertapDetails from "../../../tracking/parser/clevertap-details";

export default function withWishlist(Component) {
    const WithWishlist = (props) => {
        const {
            wishlistedCarsList,
            addOrRemoveFromWishlistConnect,
            secureToken,
            showToastConnect
        } = props;

        const isWishlistedCar = useCallback((carId) => {
            return (wishlistedCarsList || []).indexOf(carId) !== -1;
        }, [wishlistedCarsList]);

        // eslint-disable-next-line max-params
        const onClickAddToWishList = (vehicleId, token, gtmFrom, {
            comingSoon,
            make,
            model,
            shortlist,
            showNotifyPopup,
            callback = () => {},
            errorCallback = () => {},
            toastStyle,
            carStatus = {}
        } = {}, {detailsConfig, detailsContent}) => {
            const shortListed = shortlist ? shortlist : !isWishlistedCar(vehicleId);
            let params = {shortListed, vehicleId, comingSoon, make, model};
            params = carStatus.comingSoon ? {...params, ...carStatus} : params;
            addOrRemoveFromWishlistConnect(token ? token : secureToken, params).then(() => {
                if (shortListed) {
                    showToastConnect({
                        variant: "shadowGreen",
                        text: "Added to Wishlist",
                        ...(toastStyle && { toastStyle })
                    });
                    // mobileCleverTapEvent("ADD_WISHLIST", {Car_id: vehicleId,  isComingSoon: response.comingSoon}, {userAgent: {userAgent: true}});
                    trackMobileCustomEventsAU(AU_MOBILE_EVENTS.WISHLIST_CTA_SELECTED, {eventLabel: `options ${gtmFrom}`});
                } else {
                    showToastConnect({
                        variant: "shadowRed",
                        text: "Removed from Wishlist",
                        ...(toastStyle && { toastStyle })
                    });
                    // mobileCleverTapEvent("REMOVE_WISHLIST", {Car_id: vehicleId,  isComingSoon: response.comingSoon}, {userAgent: {userAgent: true}});
                    trackMobileCustomEventsAU(AU_MOBILE_EVENTS.WISHLIST_CTA_DESELECTED, {eventLabel: `options ${gtmFrom}`});
                }
                const data = clevertapDetails(detailsContent, {prefix: "wishlist_", config: detailsConfig, shortListed});
                mobileCleverTapEvent(CLEVERTAP_EVENT_MAP.WISHLIST_DONE, data);
                callback(shortListed, showNotifyPopup);
            }).catch(errorCallback);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debounceWishlist = useCallback(debounce(onClickAddToWishList, NUMBER.THREE_HUNDRED), [isWishlistedCar]);

        return (
            <Component
                {...props}
                isWishlistedCar={isWishlistedCar}
                onClickAddToWishList={debounceWishlist}
            />
        );
    };

    WithWishlist.propTypes = {
        showWishlistPopupConnect: PropTypes.func,
        fetchWishlistedCarsListConnect: PropTypes.func,
        wishlistedCarsList: PropTypes.array,
        isFetching: PropTypes.bool,
        isAdding: PropTypes.bool,
        addOrRemoveFromWishlistConnect: PropTypes.func,
        secureToken: PropTypes.string,
        showToastConnect: PropTypes.func
    };

    const mapStateToProps = ({ myWishlist: { wishlistedCarsList, isFetching, isAdding, selectedCarId }}) => {
        return { wishlistedCarsList, isFetching, isAdding, selectedCarId };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        showWishlistPopupConnect: showWishlistPopup,
        fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
        addOrRemoveFromWishlistConnect: addOrRemoveFromWishlist,
        showToastConnect: showToast
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(
        withAuth(WithWishlist)
    );
}
