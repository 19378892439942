import {FILTER_TYPES} from "./constants";

const getAppliedOptions = (filterKey, options) => {
    const appliedFilters = [];

    options.forEach((option) => {
        if (option.subFilter) {
            option.subFilter.options.forEach((subFilterOption) => {
                appliedFilters.push({
                    filterKey,
                    optionKey: option.key,
                    subOptionKey: subFilterOption.key,
                    displayText: `${option.displayText} ${subFilterOption.displayText}`
                });
            });
        } else {
            appliedFilters.push({
                filterKey,
                optionKey: option.key,
                displayText: option.displayText
            });
        }
    });

    return appliedFilters;
};

const initAppliedFilters = (filters, allFilters) => {
    let appliedFilters = [];
    let appliedSuggestions = [];
    const selectedSuggestions = [];

    // eslint-disable-next-line complexity
    Object.keys(filters).forEach((filterKey) => {
        const {
            filterType,
            min,
            max,
            options,
            suggestions
        } = filters[filterKey];

        if (filterType === FILTER_TYPES.RF) {
            appliedFilters.push({
                filterKey,
                isRangeFilter: true,
                min,
                max,
                ...({
                    displayText: options && options[0].displayText,
                    optionKey: options && options[0].displayText
                })

            });
            if (allFilters) {
                const filter = allFilters[filterKey] || {};
                let data = {};
                if (filter.suggestions) {
                    data = filter.suggestions.find(item => item.min === min && item.max === max) || {};
                }
                selectedSuggestions.push({
                    filterKey,
                    isRangeFilter: true,
                    min,
                    max,
                    ...({
                        displayText: options && options[0].displayText,
                        optionKey: options && options[0].displayText
                    }),
                    ...(data.name && {
                        displayText: data.name,
                        optionKey: data.name
                    })
                });
            }
        } else {
            if (options) {
                appliedFilters = [
                    ...appliedFilters,
                    ...getAppliedOptions(filterKey, options)
                ];
            }

            if (suggestions) {
                appliedSuggestions = [
                    ...appliedFilters,
                    ...getAppliedOptions(filterKey, suggestions)
                ];
            }
        }

    });

    return {
        selectedFilters: appliedFilters,
        selectedSuggestions: selectedSuggestions && selectedSuggestions.length ? selectedSuggestions : appliedSuggestions,
        appliedFilters,
        appliedSuggestions
    };
};

export default initAppliedFilters;
