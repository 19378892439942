import React from "react";
import styles from "./styles.css";
import Kelley from "./images/kelly.png";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { NUMBER } from "../../../constants/app-constants";
import LazyImage from "../../shared/lazy-image";
import animationData from "./images/animation.json";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});
const HowWeDetermineMarketPriceComponent  = ({kbbSliderData = {}, isModal = false}) => {
    const {kbbDifference, egc, sliderposition = NUMBER.TEN} = kbbSliderData;
    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData
    };
    return (
        <div styleName={`${isModal ? "styles.modalOuter" : "styles.outer"}`}>
            <div className="media" styleName={"styles.mediaContainer"}>
                <div className="media-body">
                    {isModal ? <p styleName={"styles.heading styles.modalHeading"}>Deals that make you go<br />"Crikey!"
                        <div styleName="styles.coinAnimationModalWrapper">
                            <Lottie isClickToPauseDisabled={true}  options={defaultLottieOptions} />
                        </div>
                    </p> :
                        <div styleName="styles.coinHeadingWrapper">
                            <div styleName="styles.coinAnimationWrapper">
                                <Lottie isClickToPauseDisabled={true}  options={defaultLottieOptions} />
                            </div>
                            <p styleName={"styles.heading"}>Deals that make you go<br />"Crikey!"</p>
                        </div>
                    }
                    <p styleName={"styles.subHeading"}>
                        {isModal ? "CARS24 offers below market prices according to Kelley Blue Book price advisory." : "We hustle hard to guarantee lower prices than other dealers. No ifs, ands, or buts!" }
                    </p>
                </div>
                {isModal && <LazyImage src={Kelley} alt="Kelley Blue Book image " />}
            </div>
            <div styleName={"styles.sliderWrapper"}>
                <div styleName={"styles.slider"}>
                    <div styleName={"styles.tooltipContainer"} style={{left: `${sliderposition}%`}}>
                        <p styleName={"styles.tooltipHeading"}>This car</p>
                        <p styleName={"styles.tooltipPrice"}>{makePriceLabelRound(egc)}</p>
                    </div>
                </div>
            </div>
            <div styleName={"styles.sliderContentWrap"}>
                <p styleName={"styles.text styles.leftAlign"}>Below<br />Market Price</p>
                <p styleName={"styles.text styles.centerAlign"}>Market<br />Price</p>
                <p styleName={"styles.text styles.rightAlign"}>Above<br />Market Price</p>
            </div>
            <p styleName={"styles.disclaimer"}>CARS24 rates this vehicle as 'Below Market Price' due to its listing at <span>{makePriceLabelRound(kbbDifference)}</span> less than the market average.</p>
        </div>
    );
};

HowWeDetermineMarketPriceComponent.propTypes = {
    kbbSliderData: PropTypes.bool,
    isModal: PropTypes.bool
};

export default HowWeDetermineMarketPriceComponent;
